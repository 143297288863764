<!--
 * @description:名称
 * @author: Long 
 * @version: 1.0
 * @updateDate: 2022-11-29 10:16:10
 * @usage:用法
    hasIcon:是否有图标
    <basic-tree :Treedata="orgsTree" :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" :hasIcon="true"></basic-tree >
-->
<template>
  <div class="tree_content">
    <el-row style="height: 100%;" :gutter="8" >
      <el-col :span="24">
        <el-input placeholder="输入关键字进行过滤" v-model="filterText" clearable> </el-input>
      </el-col>
      <!-- <el-col :span="6" style="line-height: 33px;">
        <el-checkbox v-model="isSelectAll" @change="selectAllTree"><span v-if="!isSelectAll">全选</span><span v-else>取消</span></el-checkbox>
      </el-col> -->
    </el-row>
    <!-- <el-button @click="getCheckedNodes">获取所选节点数据集合</el-button>
    <el-button @click="getCheckedKeys" >获取所选节点id集合</el-button> -->
    

    <!-- 有图标的tree -->
    <template v-if="hasIcon">
      <el-tree class="filter-tree" :data="Treedata" :expand-on-click-node="false" node-key="id" :props="defaultProps" default-expand-all :filter-node-method="filterNode" ref="basicTree" :show-checkbox="isShowCheck" @check-change="handleNodeClick" @node-click="nodeClickOne"  highlight-current>
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <!-- <i class="el-icon-office-building" v-if="data.areaNum"  style="color: #1989fa;" ><span style="color: #606266;margin-left:5px;">{{ node.label }}</span></i> -->
          <span v-if="data.areaNum != '▩'">{{ node.label + '(' + data.childrenNum +  ')' }}</span>
          <!-- <i class="el-icon-timer" style="color: #1989fa;" v-else><span style="color: #606266;margin-left:5px;">{{ node.label }}</span></i> -->
          <template v-else>
            <img v-if="data.meterReading" :src="iconUrl" alt="" class="icon_img_style">
            <img v-else :src="iconGrayUrl" alt="" class="icon_img_style">
            <span>{{ node.label }}</span>
          </template>
          <!-- <i class="el-icon-timer" style="color: #1989fa;" v-else><span style="color: #606266;margin-left:5px;">{{ node.label }}</span></i> -->
        </span>
      </el-tree>
    </template>
    <template v-else>
      <el-tree class="filter-tree" :data="Treedata" :expand-on-click-node="false" node-key="id" :props="defaultProps" default-expand-all :filter-node-method="filterNode" ref="basicTree" :show-checkbox="isShowCheck" @check-change="handleNodeClick"></el-tree>
    </template>
  </div>
</template>

<script>
import iconUrl from '@/assets/img/memter.png'//引入水表图片
import iconGrayUrl from '@/assets/img/memtergray.png'//引入水表图片
export default {
  data() {
    return {
      filterText:'',
      defaultProps: { // tree配置项
        children: 'children',
        label: 'label'
      },
      isSelectAll:false,
      iconUrl:iconUrl,
      iconGrayUrl:iconGrayUrl
    }
  },
  props:{
    //数据
    Treedata:[String, Object,Array],
    // 是否可选择
    isShowCheck:{
      type: Boolean,
      default: false
    },
    // 是否有图标
    hasIcon:{
      type: Boolean,
      default: false
    },
    
  },
  created() {
    
  },
  mounted() {
    console.log(this.Treedata,55)
  },
  methods: {
    // 全选取消
    selectAllTree(){
      if (this.isSelectAll) {
        // 	设置目前勾选的节点，使用此方法必须设置 node-key 属性
        this.$refs.basicTree.setCheckedNodes(this.Treedata);
      } else {
        // 全部不选中
        this.$refs.basicTree.setCheckedNodes([])
      }
    },
    // 搜索方法
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    //节点选中状态发生变化时的回调
    handleNodeClick(data){
      // console.log('节点点击返回绑定node-key(id)集合：'+this.$refs.basicTree.getCheckedKeys())
      this.$emit('handleNodeClick',this.$refs.basicTree.getCheckedKeys(),data)
    },
    // 获取所选节点数据集合
    getCheckedNodes(){
      console.log(this.$refs.basicTree.getCheckedNodes());
    },
    // 节点点击
    nodeClickOne(data){
      this.$emit('nodeClickOne',data)
    },
    // 获取所选节点id集合
    getCheckedKeys(){
      this.$emit('getCheckedKeys',this.$refs.basicTree.getCheckedKeys())
    },
    // 清空筛选
    resetChecked(){
      this.$refs.basicTree.setCheckedKeys([]);
    },
  },
  watch: {
    filterText(val) {
      this.$refs.basicTree.filter(val);
    }
  },
}
</script>
<style scoped>
  .icon_img_style{
    width: 20px;
    height: 20px;
    vertical-align: text-bottom;
    margin-right: 2px;
  }
  .icon_img_style span{
    color: #606266;
    margin-left:5px;
  }
  .custom-theme  ::v-deep .el-tree-node:focus>.el-tree-node__content, ::v-deep .el-tree-node:focus>.el-tree-node__content{
    background-color: #87CEFA;
    color: #fff !important;
  }
  .custom-theme  ::v-deep .el-tree-node:focus>.el-tree-node__content .icon_img_style span, ::v-deep .el-tree-node:focus>.el-tree-node__content .icon_img_style span{
    background-color: #87CEFA;
    color: #fff !important;
  }
  ::v-deep .el-tree-node:focus > .el-tree-node__content {
    background-color: #87CEFA;
    color: #fff;
  }
  ::v-deep .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-color: #87CEFA;
    color: #fff;
  }
</style>
